import { injectScriptToElement } from '@canalplus/mycanal-commons';
import { getSigninUrl, getSignupUrl } from '@canalplus/sdk-pass';
import { usePassSdk } from '@canalplus/sdk-pass-react';
import type { JSX } from 'react';
import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { getPublicConfig } from '../../helpers/config/config-helper';
import Portal from '../Portal/Portal';
import styles from './PlainTextHtmlTemplate.css';

export type PlainTextHtmlProps = {
  html?: string;
  script?: string;
  style?: string;
  title?: string;
};

type LinkPortals = {
  content: string;
  href: string;
  portalId: string;
};

/** This component is in charge of injecting custom styles & scripts from webService */
function PlainTextHtmlTemplate({
  html,
  script = '',
  style,
  title,
}: PlainTextHtmlProps): JSX.Element {
  const scriptRef = useRef<HTMLDivElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const [linkPortals, setLinkPortals] = useState<LinkPortals[]>([]);

  const signupUrl = usePassSdk(getSignupUrl);

  const signinUrl = usePassSdk(getSigninUrl);

  useEffect(() => {
    const scriptElement = scriptRef.current;
    const containerElement = containerRef.current;

    if (script.trim() !== '' && scriptElement) {
      injectScriptToElement(script, scriptElement);
    }

    // We need to replace injected internal <a> with a Link component
    // Transforms dangerouslySetInnerHTML <a> into Portal targets
    // Get all injected <a> with a data-href attribute
    const linkTags = containerElement?.querySelectorAll('a[data-href]');
    const authLink = containerElement?.querySelector(
      '.pass_auth_mini_actsms_myc'
    );
    const subsLink = containerElement?.querySelector('.pass_sign_gene_gene');

    if (authLink) {
      (authLink as HTMLAnchorElement).href = signinUrl({
        redirectUri: window.location.href,
      });
    }

    if (subsLink) {
      (subsLink as HTMLAnchorElement).href = signupUrl({
        application: getPublicConfig().api.hodor.defaultAppKey,
        redirectUri: window.location.href,
      });
    }

    const newLinkPortals: LinkPortals[] = [];
    if (linkTags?.length) {
      linkTags.forEach((link, index) => {
        const portalId = `portalTarget-${index}`;
        const newEl = document.createElement('span');
        const href =
          (link.attributes['data-href' as any] || {}).nodeValue || '';

        // Store <a> data as Portal props
        newLinkPortals.push({
          content: link.innerHTML,
          href,
          portalId,
        });

        // Switch <a> for Portal target
        newEl.id = portalId;
        if (link && link.parentNode) {
          link.parentNode.replaceChild(newEl, link);
        }
      });
    }

    // Update state with new LinkPortals only if there are new ones
    if (newLinkPortals.length) {
      setLinkPortals(newLinkPortals);
    }
  }, [script, signinUrl, signupUrl]);

  return (
    <div ref={containerRef}>
      {style && <div dangerouslySetInnerHTML={{ __html: style }} />}
      {title && (
        <header className={styles.plainTextHtml__header}>
          <h3 className={styles.plainTextHtml__title}>{title}</h3>
        </header>
      )}
      <div ref={scriptRef} style={{ visibility: 'hidden' }} />
      {html && <section dangerouslySetInnerHTML={{ __html: html }} />}
      {linkPortals.map((link) => (
        <Portal portalId={link.portalId} key={link.portalId}>
          <Link
            to={{ pathname: link.href, state: { fromPlainTextHtml: true } }}
            dangerouslySetInnerHTML={{ __html: link.content }}
          />
        </Portal>
      ))}
    </div>
  );
}

export default PlainTextHtmlTemplate;
